<template>

  <div>
    <client-list-add-new
      :is-add-new-client-sidebar-active.sync="isAddNewClientSidebarActive"
      :activeClient="selectedClient"
      :isDelete = "isDelete"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="newClient"
              >
                <span class="text-nowrap">Create New Client</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refClientListTable"
        class="position-relative"
        :items="rows"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Client -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-button
              variant="flat-primary"
              @click="editClient(data.item)"
            >
              {{ data.item.name }}
            </b-button>
          </b-media>
        </template>

        <template #cell(isActive)="data">
          <b-badge
            pill
            :variant="`light-${(data.item.isActive)== false ? 'danger':'success'}`"
            class="text-capitalize"
          >
            {{ (data.item.isActive) == false ? 'Passive': 'Active' }}
          </b-badge>
        </template>

        <!-- Action buttons-->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="editClient(data.item, false)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="editClient(data.item, true)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalClients"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import ClientsListFilters from './ClientListFilters.vue'
import useClientList from './useClientList'
import clientStoreModule from '../clientStoreModule'
import ClientListAddNew from './ClientListAddNew.vue'

export default {
  components: {
    // ClientsListFilters,
    ClientListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      selectedClient: null,
      isDelete: false,
    }
  },

  computed: {

  },
  mounted() {
  },
  methods: {
    newClient() {
      this.isAddNewClientSidebarActive = true
      this.selectedClient = null
      this.selectedClient = {
        id: null,
        name: '',
        clientGUID: 0,
        isActive: null,
      }
    },
    editClient(client, isDelete) {
      console.log(client)
      this.isAddNewClientSidebarActive = true
      this.selectedClient = null
      this.selectedClient = {
        id: null,
        name: '',
        clientGUID: 0,
        isActive: null,
      }
      this.selectedClient = client
      this.isDelete = isDelete
    },
  },
  setup() {
    const REGION_APP_STORE_MODULE_NAME = 'apps-organisation-client'
    // Register module
    if (!store.hasModule(REGION_APP_STORE_MODULE_NAME)) store.registerModule(REGION_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REGION_APP_STORE_MODULE_NAME)) store.unregisterModule(REGION_APP_STORE_MODULE_NAME)
    })

    const isAddNewClientSidebarActive = ref(false)

    const {
      rows,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

    } = useClientList()
    return {

      // Sidebar
      isAddNewClientSidebarActive,

      rows,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
