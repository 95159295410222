import axios from '@axios'

export const GET_REGION = 'getClient'
export const SET_REGION = 'setClient'

export default {
  namespaced: true,
  state: {
    client: {},
  },
  getters: {
    getClient(state) {
      return state.client
    },
  },
  mutations: {
    [SET_REGION](state, val) {
      state.client = val
    },
  },
  actions: {
    fetchAllClients() {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Client')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchClient(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`pamis/admin/organization/Client/GetByID?id=${id}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    addClient(ctx, ClientFormData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/organization/Client/Create', ClientFormData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    updateClient(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`pamis/admin/organization/Client/Update?id=${params.id}`, params)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    deleteClient(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`pamis/admin/organization/Client/Delete?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
